import React from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';

const PREFIX = 'TrainingSessionBooking';

const classes = {
  trainingSessionContainer: `${PREFIX}-trainingSessionContainer`,
  trainingSessionButtonContainer: `${PREFIX}-trainingSessionButtonContainer`,
  trainingSessionParagrahs: `${PREFIX}-trainingSessionParagrahs`,
  trainingSessionTitle: `${PREFIX}-trainingSessionTitle`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.trainingSessionContainer}`]: {
    marginBottom: '10px',
    border: '#0063cc 1px solid',
    padding: '1em',
  },

  [`& .${classes.trainingSessionButtonContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'row',
    gap: '1em',
    marginTop: '1em',
  },

  [`& .${classes.trainingSessionParagrahs}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1em',
    },
  },

  [`& .${classes.trainingSessionTitle}`]: {
    textAlign: 'center',
  },
}));

const Link = styled(Button)({
  backgroundColor: '#0063cc',
  borderColor: '#0063cc',
  color: 'white',
});

const TrainingSessionBooking = () => {
  const { t } = useTranslation();

  const authUser = useSelector(state => state.user);
  const getBookHref = () => {
    switch (authUser.region) {
      case 'au':
        return 'https://book.timify.com/?accountId=64c74471e40fef444be628f0&hideCloseButton=true&enterpriseId=659e8e698e8220ca289bcd1d';
      case 'eu':
        return 'https://book.timify.com/?accountId=6006e2870ee82410a396d8cc&hideCloseButton=true&enterpriseId=659e8e698e8220ca289bcd1d';
      case 'us':
        return 'https://go.rainbird.com/schedule-GSP';
      default:
        return 'https://book.timify.com/?accountId=64c74471e40fef444be628f0&hideCloseButton=true&enterpriseId=659e8e698e8220ca289bcd1d';
    }
  };

  return (
    <Root className={classes.trainingSessionContainer}>
      <div className={classes.trainingSessionParagrahs}>
        <div>
          <div className={classes.trainingSessionTitle}>{t('book training session')}</div>
          <div className={classes.trainingSessionButtonContainer}>
            <Link variant="contained" href={getBookHref()} target="_blank">
              {t('book_service')}
            </Link>
          </div>
        </div>
        <div>
          <div>{t('chat_with_gsp_support')}</div>
          <div className={classes.trainingSessionButtonContainer}>
            <Link variant="contained" href="https://gsp.chat/" target="_blank">
              {t('gsp_chat')}
            </Link>
          </div>
        </div>
      </div>
    </Root>
  );
};
export default TrainingSessionBooking;
